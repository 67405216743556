const LibSlider = function (element, options) {
    importScript(cdnjs.flickity, ()=> {
        cssLoaded(()=> {
            let parent = element.closest(`[data-controller="lib-slider"]`);
            let items = element.childElementCount;
            if(items >= options["minItems"] || options["minItems"] === undefined) {
                let flkty = new Flickity( element, {
                    cellAlign: (typeof options["cellAlign"] !== "undefined") ? options["cellAlign"] : "left",
                    groupCells: (typeof options["groupCells"] !== "undefined") ? options["groupCells"] : false,
                    setGallerySize: true,
                    autoPlay: (typeof options["autoPlay"] !== "undefined") ? options["autoPlay"] : false,
                    pageDots: true,
                    wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : true,
                    contain: true,
                    adaptiveHeight: false,
                    pauseAutoPlayOnHover: true,
                    prevNextButtons: true,
                    percentPosition: false,
                    watchCSS: (typeof options["watchCSS"] !== "undefined") ? options["watchCSS"] : false,
                });

                if (typeof options["flex"] !== undefined) {
                    element.classList.add("flickity-flex");
                }

                window.addEventListener("resize",debounce(()=> {
                    if (typeof options["flex"] !== undefined) {
                        element.classList.remove("flickity-flex");
                        flkty.resize();
                        element.classList.add("flickity-flex");
                    }
                },250));

                if(parent !== null) {
                    if (parent.querySelector("[data-lib-flickity-prev]") !== null) {
                        parent.querySelector("[data-lib-flickity-prev]").addEventListener("click", () => {
                            flkty.previous();
                        });
                    }
                    if (parent.querySelector("[data-lib-flickity-next]") !== null) {
                        parent.querySelector("[data-lib-flickity-next]").addEventListener("click", () => {
                            flkty.next();
                        });
                    }
                }
            }
        });
    });
};